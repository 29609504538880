import { useState, useEffect } from "react";
import httpRequest from "~/lib/httpClient";

/**
 * useFetch 请求数据
 * method: Post
 */
export default (url: string, body = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        httpRequest.post(url, body).then((res) => {
            setLoading(false)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setData(res as unknown as any)
        })
        .catch(err => {
            setLoading(false);
            const error = {
                message: err
            }
             // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setError(error as unknown as any)
        })
    }, [])

    return {
        data,
        loading,
        error
    }
}